<template>
  <div>
    <div class="test-paper">
      <MultipleChoiceWithPassage_M
        v-if="
          (question.sat_sat_subject.sat_subject.name === 'reading' || question.sat_sat_subject.sat_subject.name === 'english' ||
            question.sat_sat_subject.sat_subject.name === 'writing') &&
            question.type === 'default'
        "
        mode="explanation"
        :passageIntro="question.sat_passage.introduction?question.sat_passage.introduction.intro : null"
        :passageContent="getPassage"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers[0].answers[0]"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
        :activities="activities"
      ></MultipleChoiceWithPassage_M>
      <MultipleChoice_M
        v-if="
          (question.sat_sat_subject.sat_subject.name === 'math' ||
            question.sat_sat_subject.sat_subject.name === 'math calculator') &&
            question.type === 'default'
        "
        mode="explanation"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers[0].answers[0]"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :activities="activities"
      ></MultipleChoice_M>
      <Math_M
        v-if="question.type === 'math'"
        mode="explanation"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers[0].answers[0]"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :activities="activities"
      ></Math_M>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";

export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      answer: null,
      question: null,
      questionOrder: 1,
      activities: [
        {
          timestamp: "00:00",
          content: "开始答题"
        },
        {
          timestamp: "00:20",
          content: "提交答案"
        }
      ]
    };
  },
  computed: {
    questionId() {
      return this.$route.params.questionId;
    },
    user_exam_answer_id() {
      return this.$route.query.user_exam_answer_id;
    },
    getPassage() {
      let passage = "";
      let p1 = this.question.sat_passage.content;
      let p2 = this.question.sat_passage.introduction?.below_content;
      if (p2) {
        passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
      } else {
        passage = p1;
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {},

  async mounted() {
    const res = await SAT.getExplanation(this.questionId, {
      user_exam_answer_id: this.user_exam_answer_id
    });
    this.question = res.sat_question;
    this.answer = res.user_exam_answer;
  },

  methods: {
    getLine(passage) {
      let new_passage = passage.replace(/[\n]/g, "<br>");
      new_passage = new_passage.replace(
        /<br>/g,
        "</p><p style='margin-bottom:0;height:34px;'>"
      );
      new_passage =
        "<p style='margin-bottom:0;height:34px;'>" +
        new_passage +
        "</p>";
      return new_passage;
    }
  }
};
</script>

<style scoped>
 .test-paper {
  padding: 1rem 0;
 }
</style>
